
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { Toast } from 'vant'
import useOpenInfo from '@/hooks/openInfo'
import useQueryFileTemp from '@/hooks/useFileTemp'
import { ImageType } from '@/enum'
import useMutationCommon from '@/hooks/useMutationCommon'
import stepPercentage from '@/utils/number'
import useSaveOpenInfo from '@/hooks/useSaveOpenInfo'
import { openFileView, setApptitle } from '@/utils/native-app'

export default defineComponent({
  setup () {
    const router = useRouter()
    const {
      meta,
      openType,
      openInfo,
      step,
      title,
      saveOpenInfoOfVuex,
      saveCommonOfVuex
    } = useOpenInfo()
    const isPiVerify = openInfo.value.isPiVerify
    let filelist = [
      { text: '《客户协议书》', name: 'GHSLClientAgreement(SimpChi)Nov2021.pdf' },
      { text: '《客户声明》', name: 'client_statement' },
      { text: '《风险披露声明》', name: 'risk_statement' },
      { text: '《海外账户税收合规法案声明》', name: 'fatca' },
      { text: '《金马证券收费表》', name: 'feetable.pdf' },
      { text: '《个人资料收集声明》', name: 'personal_info' },
      { text: '《专业投资者评估及声明书》', name: 'PIAssessmentAndDeclaration.pdf' },
      { text: '《自动交换财务账户资料声明》', name: 'accinfo_statement' }
    ]
    const files = computed(() => {
      if (isPiVerify === 0) {
        filelist = filelist.filter(item => item.text !== '《专业投资者评估及声明书》')
      }
      return filelist
    })
    const agreeOpenFile = ref(false)
    const showPdf = (name: string, text: string) => {
      const url = `${process.env.VUE_APP_H5_STATIC_PAGE_URL + '/static_pages/pdf/' + name}`
      const title = text
      const params = {
        fileType: 'pdf',
        fileUrl: url,
        title
      }
      openFileView(params)
    }
    const toFile = (name: string, text: string) => {
      if (!name.includes('.pdf')) {
        window.location.href = `${process.env.VUE_APP_H5_STATIC_PAGE_URL + '/static_pages/' + name}.html`
      } else {
        showPdf(name, text)
      }
    }
    const res = useQueryFileTemp({
      kindSource: openType.value
    })
    const signImageUrl = ref()
    // 缓存图片列表
    watch(res, newValue => {
      const list = newValue.filter(
        (val: { imageType: number; idKindType: number }) =>
          val.imageType === ImageType.ACC_IMAGE_TYPE_301 && val.idKindType === openInfo.value.idKind
      )
      signImageUrl.value = list.length ? list[0].remoteUrl : ''
    })

    // 用户信息回填
    const initData = () => {
      agreeOpenFile.value = openInfo.value?.agreeOpenFile
    }
    const isDisabled = computed(() => !(agreeOpenFile.value && signImageUrl.value))

    // 客户签名
    const signatureHandle = () => {
      router.push({ name: 'signature' })
    }

    // 保存步骤信息
    // 保存下一步调用接口
    const saveNext = () => {
      const percentage = stepPercentage(step)
      const params = {
        formType: 'openAccount',
        meta: meta.value,
        content: JSON.stringify({ agreeOpenFile: agreeOpenFile.value })
      }
      const common = {
        openType: openType.value,
        openStatus: 0,
        btnText: '继续开户',
        remark: `已完成${percentage}%，请继续完善资料`,
        step
      }
      useSaveOpenInfo(params)
      useMutationCommon(common).then(() => {
        saveCommonOfVuex({ info: common })
        router.push({ name: 'transfer-step' })
      })
    }
    const handleNext = () => {
      if (!signImageUrl.value) {
        Toast('请手写签名')
        return
      }
      saveNext()
    }
    // 保存步骤信息回调
    const changeAgree = () => {
      saveOpenInfoOfVuex({ info: { agreeOpenFile: agreeOpenFile.value } })
    }

    onMounted(() => {
      setApptitle(title)
      initData()
    })

    return {
      handleNext,
      files,
      agreeOpenFile,
      signatureHandle,
      signImageUrl,
      isDisabled,
      toFile,
      changeAgree,
      showPdf,
      isPiVerify
    }
  }
})
